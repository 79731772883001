/* Font Class */

@import url('/fonts/iconfont.css');

/* Unicode */
@font-face {
  font-family: 'iconfont';  /* project id 1022015 */
  src: url('/fonts/iconfont.eot');
  src: url('/fonts/iconfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/iconfont.woff2') format('woff2'),
  url('/fonts/iconfont.woff') format('woff'),
  url('/fonts/iconfont.ttf') format('truetype'),
  url('/fonts/iconfont.svg#iconfont') format('svg');
}


.iconfont{
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}